/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import AdhocMetric from 'src/explore/components/controls/MetricControl/AdhocMetric';
import { QueryFormData } from '@superset-ui/core';
import { ControlStateMapping } from '@superset-ui/chart-controls';
import {
  jsonToFilterGroup,
  translateToSqlQuery,
} from 'src/explore/components/controls/AdvancedMetricFilterControl/AdvancedMetricFilterControl';

export function getFormDataFromControls(
  controlsState: ControlStateMapping,
  columns = [],
  savedColumns = [],
  cohorts = [],
  savedCohorts = [],
): QueryFormData {
  const formData: any = {};
  Object.keys(controlsState).forEach(controlName => {
    const control = controlsState[controlName];
    formData[controlName] = control.value;
  });

  const { measurements, viz_type, adhoc_filters } = formData;

  // Check if this chart has saved columns or saved cohorts
  if (savedColumns?.length > 0 || savedCohorts?.length > 0) {
    // Get datasource columns
    const cols = controlsState?.datasource?.datasource?.columns ?? columns;
    const allCohorts =
      controlsState?.datasource?.datasource?.saved_cohorts ?? cohorts;

    // Check global filter
    if (adhoc_filters?.length > 0) {
      const { filterGroup, sqlExpression } = adhoc_filters[0];
      const filterSavedColumns = adhoc_filters[0]?.savedColumns;
      const filterSavedCohorts = adhoc_filters[0]?.savedCohorts;

      if (
        filterGroup &&
        ((filterSavedColumns?.length > 0 && cols.length > 0) ||
          (filterSavedCohorts?.length > 0 && allCohorts.length > 0))
      ) {
        // Get the lateast saved columns
        try {
          const newFilterGroup = jsonToFilterGroup(
            filterGroup,
            cols,
            allCohorts,
          );
          formData.adhoc_filters[0].sqlExpression =
            translateToSqlQuery(newFilterGroup);
        } catch (e) {
          // filter miss column param in json will lead to jsonToFilterGroup raise error, now the bug is fixed, add the catch to open some chart which has this bug before rather than modify db.
          formData.adhoc_filters[0].sqlExpression = sqlExpression;
        }
      }
    }

    // Get new measurements
    if (measurements && viz_type !== 'score_card') {
      formData.measurements = measurements.map((measure: any) => {
        const newMeasure = { ...measure };
        // Measure filter
        const measureFilter = measure.AdhocFilterControl;
        // Measure column
        const columnExpression = measure.metric.column?.expression;

        // If measure has filters or the measure column is saved column,
        // get the new column name, measure filters and sql expression
        if (measureFilter?.length > 0 || columnExpression) {
          // Get new filter and filter sql
          if (measureFilter?.length > 0) {
            const {
              filterGroup,
              sqlExpression,
              savedColumns: filteColumns,
              savedCohorts: filteCohorts,
            } = measureFilter[0];

            if (
              filterGroup &&
              ((cols?.length > 0 && filteColumns?.length > 0) ||
                (allCohorts?.length > 0 && filteCohorts?.length > 0))
            ) {
              try {
                const newFilterGroup = jsonToFilterGroup(
                  filterGroup,
                  cols,
                  allCohorts,
                );
                const newSql = translateToSqlQuery(newFilterGroup);
                newMeasure.AdhocFilterControl[0].sqlExpression = newSql;
                newMeasure.metric.filterSql = newSql;
              } catch (e) {
                // filter miss column param in json will lead to jsonToFilterGroup raise error, now the bug is fixed, add the catch to open some chart which has this bug rather than modify db.
                newMeasure.AdhocFilterControl[0].sqlExpression = sqlExpression;
                newMeasure.metric.filterSql = sqlExpression;
              }
            }
          }

          // Get new column for measure column
          if (columnExpression) {
            const columnId = measure?.metric?.column?.id;
            if (columnExpression && columnId) {
              const column = cols.find((col: any) => col.id === columnId);
              if (column) {
                newMeasure.SelectControlColumn = column?.column_name;
                newMeasure.metric.column = column;
              }
            }
          }

          // Get new measure sql expression
          const adhocMetric = new AdhocMetric({
            ...measure.metric,
            expressionType: 'SIMPLE',
          });
          const newSqlExpression = adhocMetric.translateAdvancedMetricsToSql(
            measure.metric.filterSql,
          );
          newMeasure.metric.sqlExpression = newSqlExpression;
        }
        return newMeasure;
      });
    }
  }

  // HACK: this is a temporary workaround to fix some charts where measurements and metrics are not in sync
  if (viz_type !== 'score_card' && measurements) {
    formData.metrics = measurements.map(
      (measurement: any) => measurement.metric,
    );
  }

  return formData as QueryFormData;
}
