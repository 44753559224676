/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import rison from 'rison';
import { SupersetClient, t } from '@superset-ui/core';
import { addSuccessToast } from 'src/components/MessageToasts/actions';
import { isEmpty } from 'lodash';
import { buildV1ChartDataPayload } from '../exploreUtils';
import { Operators } from '../constants';

const ADHOC_FILTER_REGEX = /^adhoc_filters/;

export const FETCH_DASHBOARDS_SUCCEEDED = 'FETCH_DASHBOARDS_SUCCEEDED';
export function fetchDashboardsSucceeded(choices) {
  return { type: FETCH_DASHBOARDS_SUCCEEDED, choices };
}

export const FETCH_DASHBOARDS_FAILED = 'FETCH_DASHBOARDS_FAILED';
export function fetchDashboardsFailed(userId) {
  return { type: FETCH_DASHBOARDS_FAILED, userId };
}

export const SET_SAVE_CHART_MODAL_VISIBILITY =
  'SET_SAVE_CHART_MODAL_VISIBILITY';
export function setSaveChartModalVisibility(isVisible) {
  return { type: SET_SAVE_CHART_MODAL_VISIBILITY, isVisible };
}

export const SAVE_SLICE_FAILED = 'SAVE_SLICE_FAILED';
export function saveSliceFailed() {
  return { type: SAVE_SLICE_FAILED };
}
export const SAVE_SLICE_SUCCESS = 'SAVE_SLICE_SUCCESS';
export function saveSliceSuccess(data) {
  return { type: SAVE_SLICE_SUCCESS, data };
}

export const SAVE_MEASURE_FAILED = 'SAVE_MEASURE_FAILED';
export function saveMeasureFailed() {
  return { type: SAVE_MEASURE_FAILED };
}

export const SAVE_MEASURE_SUCCESS = 'SAVE_MEASURE_SUCCESS';
export function saveMeasureSuccess(data) {
  return { type: SAVE_MEASURE_SUCCESS, data };
}

export const SAVE_COHORT_FAILED = 'SAVE_COHORT_FAILED';
export function saveCohortFailed() {
  return { type: SAVE_COHORT_FAILED };
}

export const SAVE_COHORT_SUCCESS = 'SAVE_COHORT_SUCCESS';
export function saveCohortSuccess(data) {
  return { type: SAVE_COHORT_SUCCESS, data };
}

export const SAVE_COLUMN_FAILED = 'SAVE_COLUMN_FAILED';
export function saveColumnFailed(data) {
  return { type: SAVE_COLUMN_FAILED, data };
}

export const SAVE_COLUMN_SUCCESS = 'SAVE_COLUMN_SUCCESS';
export function saveColumnSuccess(data) {
  return { type: SAVE_COLUMN_SUCCESS, data };
}

const extractAddHocFiltersFromFormData = formDataToHandle =>
  Object.entries(formDataToHandle).reduce(
    (acc, [key, value]) =>
      ADHOC_FILTER_REGEX.test(key)
        ? { ...acc, [key]: value?.filter(f => !f.isExtra) }
        : acc,
    {},
  );

const hasTemporalRangeFilter = formData =>
  (formData?.adhoc_filters || []).some(
    filter => filter.operator === Operators.TEMPORAL_RANGE,
  );

// Get cohort top n series from the queryresponse when saving a cohort
export const getCohortTopSeries = (queryResponse, dimensions) => {
  const result = queryResponse?.data;

  // If the query response or dimension is empty, return []
  if (!dimensions || !result) {
    return [];
  }

  // Filter the result to only include the dimensions
  return result.map(item => {
    const filtered = {};
     Object.keys(item).forEach(key => {
      if (dimensions.includes(key)) {
        filtered[key] = item[key];
      }
    });
    return filtered;
  }).filter(filtered => Object.keys(filtered).length > 0);
};

export const getSlicePayload = (
  sliceName,
  formDataWithNativeFilters,
  dashboards,
  owners,
  formDataFromSlice = {},
  columns = [],
) => {
  const adhocFilters = extractAddHocFiltersFromFormData(
    formDataWithNativeFilters,
  );

  // Retain adhoc_filters from the slice if no adhoc_filters are present
  // after overwriting a chart.  This ensures the dashboard can continue
  // to filter the chart. Before, any time range filter applied in the dashboard
  // would end up as an extra filter and when overwriting the chart the original
  // time range adhoc_filter was lost
  if (isEmpty(adhocFilters?.adhoc_filters) && !isEmpty(formDataFromSlice)) {
    formDataFromSlice?.adhoc_filters?.forEach(filter => {
      if (filter.operator === Operators.TEMPORAL_RANGE && !filter.isExtra) {
        adhocFilters.adhoc_filters.push({ ...filter, comparator: 'Last day' });
      }
    });
  }

  // This loop iterates through the adhoc_filters array in formDataWithNativeFilters.
  // If a filter is of type TEMPORAL_RANGE and isExtra, it sets its comparator to
  // 'No filter' and adds the modified filter to the adhocFilters array. This ensures that all
  // TEMPORAL_RANGE filters are converted to 'No filter' when saving a chart.
  if (!hasTemporalRangeFilter(adhocFilters)) {
    formDataWithNativeFilters?.adhoc_filters?.forEach(filter => {
      if (filter.operator === Operators.TEMPORAL_RANGE && filter.isExtra) {
        adhocFilters.adhoc_filters.push({ ...filter, comparator: 'Last day' });
      }
    });
  }

  const formData = {
    ...formDataWithNativeFilters,
    adhoc_filters: formDataWithNativeFilters.adhoc_filters?.filter(
      f => !f.isExtra,
    ),
    dashboards,
  };

  const { metrics, metric, measurements, adhoc_filters: globalFilters, groupby } = formData;
  const savedCols = new Set();
  const savedCohorts = new Set();
  if (metric && metric?.column?.expression) {
    savedCols.add(metric.column.id)
  }
  if (metrics) {
    metrics.forEach(m => {
      if (m.column?.expression) {
        savedCols.add(m.column.id)
      }
    });
  }

  // Saved columns in filters
  if (globalFilters?.length > 0) {
    const colsInFilter = globalFilters[0]?.savedColumns || [];
    colsInFilter.forEach(item => savedCols.add(item));
    const cohortsInFilter = globalFilters[0]?.savedCohorts || [];
    cohortsInFilter.forEach(item => savedCohorts.add(item));
  }

  // Saved columns in metrics
  if (measurements) {
    measurements.forEach(measure => {
      const measureFilter = measure.AdhocFilterControl;
      if (measureFilter && measureFilter.length > 0) {
        const colsInFilter = measureFilter[0]?.savedColumns || [];
        colsInFilter.forEach(item => savedCols.add(item));
        const cohortsInFilter = measureFilter[0]?.savedCohorts || [];
        cohortsInFilter.forEach(item => savedCohorts.add(item));
      }
    });
  }

  // Saved columns in dimensions
  if (groupby?.length > 0 && columns.length > 0) {
    groupby.forEach(item => {
      const col = columns.find(c => c.column_name === item);
      if (col && col.expression) {
        savedCols.add(col.id);
      }
    });
  }

  savedCols.delete(undefined);
  formData.savedColumns = [...savedCols];
  formData.savedCohorts = [...savedCohorts];

  const [datasourceId, datasourceType] = formData.datasource.split('__');
  const payload = {
    params: JSON.stringify(formData),
    slice_name: sliceName,
    viz_type: formData.viz_type,
    datasource_id: parseInt(datasourceId, 10),
    datasource_type: datasourceType,
    dashboards,
    owners,
    saved_measures: formData.saved_measures,
    saved_cols: formData.savedColumns,
    saved_cohorts: formData.savedCohorts,
    query_context: JSON.stringify(
      buildV1ChartDataPayload({
        formData,
        force: false,
        resultFormat: 'json',
        resultType: 'full',
        setDataMask: null,
        ownState: null,
      }),
    ),
  };
  return payload;
};

export const getMeasurePayload = (
  measureName,
  formDataWithNativeFilters,
  owners,
) => {
  const formData = {
    ...formDataWithNativeFilters,
    adhoc_filters: formDataWithNativeFilters.adhoc_filters?.filter(
      f => !f.isExtra,
    ),
  };

  const [datasourceId, datasourceType] = formData.datasource.split('__');
  const payload = {
    params: JSON.stringify(formData),
    measure_name: measureName,
    viz_type: formData.viz_type,
    datasource_id: parseInt(datasourceId, 10),
    datasource_type: datasourceType,
    dashboards: formData.dashboards,
    tags: formData.tags,
    owners,
    query_context: JSON.stringify(
      buildV1ChartDataPayload({
        formData,
        force: false,
        resultFormat: 'json',
        resultType: 'full',
        setDataMask: null,
        ownState: null,
      }),
    ),
  };
  return payload;
};

// Get cohort upodate/create request payload
export const getCohortPayload = (
  cohortName,
  formDataWithNativeFilters,
  queryResponse,
  owners,
) => {
  const dimensions = formDataWithNativeFilters?.groupby;
  const formData = {
    ...formDataWithNativeFilters,
    adhoc_filters: formDataWithNativeFilters.adhoc_filters?.filter(
      f => !f.isExtra,
    ),
  };
  const series = getCohortTopSeries(queryResponse, dimensions);

  const [datasourceId, datasourceType] = formData.datasource.split('__');
  const payload = {
    params: JSON.stringify(formData),
    cohort_name: cohortName,
    viz_type: formData.viz_type,
    datasource_id: parseInt(datasourceId, 10),
    datasource_type: datasourceType,
    dashboards: formData.dashboards,
    tags: formData.tags,
    owners,
    top_series: JSON.stringify(series),
    actor: formData.actor,
    query_context: JSON.stringify(
      buildV1ChartDataPayload({
        formData,
        force: false,
        resultFormat: 'json',
        resultType: 'full',
        setDataMask: null,
        ownState: null,
      }),
    ),
  };
  return payload;
};

export const getSavedColumnPayload = (
  columnName,
  formDataWithNativeFilters,
  owners,
) => {
  const formData = {
    ...formDataWithNativeFilters,
    adhoc_filters: formDataWithNativeFilters.adhoc_filters?.filter(
      f => !f.isExtra,
    ),
  };
  let payload = {};
  if (formData.viz_type === 'saved_column') {
    const expression = formData.groupby[0].sqlExpression;
    const { SavedColumns } = formData;
    const filterable = SavedColumns.isFilterable || false;
    const groupby = SavedColumns.isDimension || false;
    const type = SavedColumns.dataType;
    const { method } = SavedColumns;
    payload = {
      type,
      expression,
      filterable,
      groupby,
      method,
    }
  }
  else {
    const expression = formData.columns[formData.columns.length - 1].sqlExpression;
    const { CalculatedColumns } = formData;
    const filterable = CalculatedColumns.isFilterable || false;
    const groupby = CalculatedColumns.isDimension || false;
    let type = CalculatedColumns.dataType;
    let isDttm = false;
    if (['HOUR_OF_DAY', 'DAY_OF_WEEK', 'DAY_OF_MONTH', 'DAY_OF_YEAR', 'WEEK_OF_YEAR', 'MONTH_OF_YEAR'].includes(CalculatedColumns.timeOperator)) {
      type = 'NUMERIC'
    }
    if (type === 'DATETIME') {
      isDttm = true;
    }
    const { method } = CalculatedColumns;
    payload = {
      type,
      expression,
      filterable,
      groupby,
      method,
      is_dttm:isDttm,
    }
  }


  const [datasourceId] = formData.datasource.split('__');
  payload = {
    ...payload,
    form_data: JSON.stringify(formData),
    column_name: columnName,
    // viz_type: formData.viz_type,
    table_id: parseInt(datasourceId, 10),
    owners,
  };
  return payload;
}

const addToasts = (isNewSlice, sliceName, addedToDashboard, chartType) => {
  const toasts = [];
  if (isNewSlice) {
    toasts.push(addSuccessToast(t('%s [%s] has been saved', chartType, sliceName)));
  } else {
    toasts.push(
      addSuccessToast(t('%s [%s] has been overwritten', chartType, sliceName)),
    );
  }

  if (addedToDashboard) {
    if (addedToDashboard.new) {
      toasts.push(
        addSuccessToast(
          t(
            'Dashboard [%s] just got created and chart [%s] was added to it',
            addedToDashboard.title,
            sliceName,
          ),
        ),
      );
    } else {
      toasts.push(
        addSuccessToast(
          t(
            'Chart [%s] was added to dashboard [%s]',
            sliceName,
            addedToDashboard.title,
          ),
        ),
      );
    }
  }

  return toasts;
};

//  Update existing slice
export const updateSlice =
  (slice, sliceName, dashboards, addedToDashboard, columns) =>
    async (dispatch, getState) => {
      const { slice_id: sliceId, owners, form_data: formDataFromSlice } = slice;
      const {
        explore: {
          form_data: { url_params: _, ...formData },
        },
      } = getState();
      const savedMeasures = [];
      if (formData.measurements) {
        formData.measurements.map(measure => {
          if (measure.measure_id) savedMeasures.push(measure.measure_id);
          if (measure.measure_ids) savedMeasures.push(...measure.measure_ids);
          return {};
        });
      }
      try {
        const response = await SupersetClient.put({
          endpoint: `/api/v1/chart/${sliceId}`,
          jsonPayload: getSlicePayload(
            sliceName,
            { ...formData, saved_measures:savedMeasures },
            dashboards,
            owners,
            { ...formDataFromSlice, saved_measures:savedMeasures },
            columns,
          ),
        });

        dispatch(saveSliceSuccess());
        addToasts(false, sliceName, addedToDashboard, 'Chart').map(dispatch);
        return response.json;
      } catch (error) {
        dispatch(saveSliceFailed());
        throw error;
      }
    };

//  Create new slice
export const createSlice =
  (sliceName, dashboards, addedToDashboard, columns) => async (dispatch, getState) => {
    const {
      explore: {
        form_data: { url_params: _, ...formData },
      },
    } = getState();
    const savedMeasures = [];
    if (formData.measurements) {
      formData.measurements.map(measure => {
        if (measure.measure_id) savedMeasures.push(measure.measure_id);
        if (measure.measure_ids) savedMeasures.push(...measure.measure_ids);
        return {};
      });
    }
    const newFormData = { ...formData, saved_measures: savedMeasures};
    try {
      const response = await SupersetClient.post({
        endpoint: `/api/v1/chart/`,
        jsonPayload: getSlicePayload(sliceName, newFormData, dashboards, [], {}, columns),
      });

      dispatch(saveSliceSuccess());
      addToasts(true, sliceName, addedToDashboard, 'Chart').map(dispatch);
      return response.json;
    } catch (error) {
      dispatch(saveSliceFailed());
      throw error;
    }
  };

// Create new cohort
export const createCohort = (cohortName, formData, queryResponse) => async dispatch => {
  try {
    const response = await SupersetClient.post({
      endpoint: `/api/v1/cohort/`,
      jsonPayload: getCohortPayload(cohortName, formData, queryResponse),
    });

    dispatch(saveCohortSuccess());
    addToasts(true, cohortName, undefined, 'Cohort').map(dispatch);
    return response.json;
  } catch (error) {
    dispatch(saveCohortFailed());
    throw error;
  }
};

// update a cohort
export const updateCohort =
  ({ cohort_id: cohortId, owners }, cohortName, formData, queryResponse) =>
    async dispatch => {
      try {
        const response = await SupersetClient.put({
          endpoint: `/api/v1/cohort/${cohortId}`,
          jsonPayload: getCohortPayload(cohortName, formData, queryResponse, owners),
        });
        dispatch(saveCohortSuccess());
        addToasts(false, cohortName, undefined, 'Cohort').map(dispatch);
        return response.json;
      } catch (error) {
        dispatch(saveCohortFailed());
        throw error;
      }
    };

//  Create new dashboard
export const createDashboard = dashboardName => async dispatch => {
  try {
    const response = await SupersetClient.post({
      endpoint: `/api/v1/dashboard/`,
      // Dashboards with empty json_metadata will be hidden on the dashboard list
      // For the dashboard created from chart, add default value to json_metadata to make it visible
      jsonPayload: {
        dashboard_title: dashboardName,
        json_metadata: '{"color_scheme":""}',
      },
    });

    return response.json;
  } catch (error) {
    dispatch(saveSliceFailed());
    throw error;
  }
};

export const updateMeasure =
  ({ measure_id: measureId, owners }, measureName, formData) =>
    async dispatch => {
      const tags = formData.measurements[0].tags || [];
      const newFormData = {
        ...formData,
        tags,
      };
      try {
        const response = await SupersetClient.put({
          endpoint: `/api/v1/measure/${measureId}`,
          jsonPayload: getMeasurePayload(measureName, newFormData, owners),
        });
        dispatch(saveMeasureSuccess());
        addToasts(false, measureName, undefined, 'Measure').map(dispatch);
        return response.json;
      } catch (error) {
        dispatch(saveMeasureFailed());
        throw error;
      }
    };

// Create new measure
export const createMeasure = (measureName, formData) => async dispatch => {
  const tags = formData.measurements[0].tags || '';
  const newFormData = {
    ...formData,
    tags,
  };
  try {
    const response = await SupersetClient.post({
      endpoint: `/api/v1/measure/`,
      jsonPayload: getMeasurePayload(measureName, newFormData),
    });

    dispatch(saveMeasureSuccess());
    addToasts(true, measureName, undefined, 'Measure').map(dispatch);
    return response.json;
  } catch (error) {
    dispatch(saveMeasureFailed());
    throw error;
  }
};

// Create new column
export const createColumn = (columnName, formData, owners) => async dispatch => {
  try {
    const response = await SupersetClient.post({
      endpoint: `/api/v1/dataset/saved_column/`,
      jsonPayload: getSavedColumnPayload(columnName, formData, owners),
    });

    dispatch(saveColumnSuccess());
    addToasts(true, columnName, undefined, 'Column').map(dispatch);
    return response.json;
  } catch (error) {
    dispatch(saveColumnFailed());
    throw error;
  }
};

// Update existing column
export const updateColumn = (savedColumn, columnName, formData) => async dispatch => {
  const { owners, id } = savedColumn;
  const formatOwners = owners.map(owner => owner.id);
  try {
    const response = await SupersetClient.put({
      endpoint: `/api/v1/dataset/saved_column/${id}/`,
      jsonPayload: getSavedColumnPayload(columnName, formData, formatOwners),
    }
    );
    dispatch(saveColumnSuccess());
    addToasts(false, columnName, undefined, 'Column').map(dispatch);
    return response.json;
  } catch (error) {
    dispatch(saveColumnFailed());
    throw error;
  }
};

//  Get dashboards the slice is added to
export const getSliceDashboards = slice => async dispatch => {
  try {
    const response = await SupersetClient.get({
      endpoint: `/api/v1/chart/${slice.slice_id}?q=${rison.encode({
        columns: ['dashboards.id'],
      })}`,
    });

    return response.json.result.dashboards.map(({ id }) => id);
  } catch (error) {
    dispatch(saveSliceFailed());
    throw error;
  }
};
