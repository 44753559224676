/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { CalValueTypes } from './multiMetricTypes';

export function validateCalculation(
  value: any,
  savedMeasures: any,
  allMeasures: any,
) {
  // Check if the value is empty string or undefined
  if (value === undefined || value === null || value.trim() === '') {
    return {
      isValidExpression: false,
      validationErrors: 'Calculated measure cannot be empty.',
    };
  }
  // Check if this string value have "paired" quotes.
  if (!checkQuotes(value)) {
    return {
      isValidExpression: false,
      validationErrors: 'Please check if you miss any quotes.',
    };
  }

  // Process the value to validate.
  // Split the value into an array of values and operators.
  // Some olds saved measure have operators in their names, like "(Share + Copy Link + Send to)".
  // so we will split the value by quotes first.
  // and then split the value by operators.
  const processcalValue = processValueForValidate(value);
  const { isValidExpression, validationErrors } =
    validateExpression(processcalValue);
  if (!isValidExpression) return { isValidExpression, validationErrors };

  // Check if the content within quotes is saved measure or measure in chart.
  const measures = extractContentWithinQuotes(value);
  for (let i = 0; i < measures.length; i += 1) {
    const measure = measures[i];
    const measureType = getCalValueType(measure, savedMeasures, allMeasures);
    if (
      measureType !== CalValueTypes.SavedMeasure &&
      measureType !== CalValueTypes.ChartMeasure
    )
      return {
        isValidExpression: false,
        validationErrors: 'Please check if your measure exists.',
      };
  }
  return {
    isValidExpression: true,
    validationErrors: '',
  };
}

export function getCalMeasureArray(value: any) {
  if (value === undefined || value.length === 0) return [];
  const pattern = /"[^"]*"|\d+\.\d+|\d+|>=|<=|[+\-*/()<>]/g;
  const valueArray = value.match(pattern);
  if (valueArray) {
    return valueArray.map((value: string) => value.replace(/^"|"$/g, ''));
  }
  return [];
}

// Check if this string value have "paired" quotes.
export function checkQuotes(str: string) {
  let count = 0;
  for (let i = 0; i < str.length; i += 1) {
    if (str[i] === '"') {
      count += 1;
    }
  }
  return count % 2 === 0;
}

export function extractContentWithinQuotes(inputString: any) {
  if (inputString === undefined || inputString === null) return [];
  const measures = inputString.match(/"([^"]*)"/g) || [];
  return measures.map((match: any) => match.slice(1, -1));
}

// Process value for validation.
export function processValueForValidate(str: string) {
  // Replace all measures with "
  const strMergeQuotes = str.replace(/"([^"]*)"/g, '"');
  // Replace all numbers with 0
  const strMergeNumber = strMergeQuotes.replace(/\d+(\.\d+)?/g, '0');
  // Remove all white sapces
  const strWithoutSpaces = strMergeNumber.replace(/\s/g, '');

  // Replace all operators with +
  const strMergeOperators = strWithoutSpaces.replace(/>=|<=|[+\-*/<>]/g, '+');

  return strMergeOperators;
}

export function validateExpression(str: string) {
  let leftParent = 0;
  for (let i = 0; i < str.length; i += 1) {
    const value = str[i];
    const prev_value = i > 0 ? str[i - 1] : undefined;
    const next_value = i < str.length - 1 ? str[i + 1] : undefined;

    // Handle the case when value is '('
    if (value === '(') {
      // The next value should not be ')', and the previous valud should not be '('
      // eg: '()', ')(', 'measure(' are invalid
      if (i === str.length - 1 || next_value === ')')
        return {
          isValidExpression: false,
          validationErrors:
            '( cannot be the end of an expression, and () is invalid in calculated measure.',
        };
      if (i > 0 && prev_value === ')')
        return {
          isValidExpression: false,
          validationErrors: ')( is invalid calculated measure.',
        };
      leftParent += 1;
    } else if (value === ')') {
      // The next value should not be ')', and the previous value should not be '('
      // eg: ')measure', '(measure))' are invalid value
      if (i === 0 || prev_value === '(' || leftParent === 0)
        return {
          isValidExpression: false,
          validationErrors:
            '( cannot be the beginning of an expression, and )( is in valid in calculated measure.',
        };
      leftParent -= 1;
    } else if (value === '+') {
      // Handle the case when value is an operator
      if (i === 0 || i === str.length - 1)
        return {
          isValidExpression: false,
          validationErrors: 'Expression cannot begin or end with an operator',
        };
      if (prev_value === '+' || next_value === '+')
        return {
          isValidExpression: false,
          validationErrors:
            'Two operators cannot appear together in an expression',
        };
      if (prev_value === '(' || next_value === ')')
        return {
          isValidExpression: false,
          validationErrors:
            'Two operators cannot appear together in an expression',
        };
    } else if (value === '"' || value === '0') {
      // Handle the case when value is a measure or number
      if (i > 0 && prev_value === ')')
        return {
          isValidExpression: false,
          validationErrors: 'The left of a measure or number cannot be )',
        };
      if (i < str.length - 1) {
        if (next_value === '(')
          return {
            isValidExpression: false,
            validationErrors: 'The right of a measure or number cannot be (',
          };
        if (next_value === '"' || next_value === '0')
          return {
            isValidExpression: false,
            validationErrors:
              'Two measures or numbers cannot appear together in an expression',
          };
      }
    } else {
      return {
        isValidExpression: false,
        validationErrors:
          'Please check if your expression only contains measure, number and operator. Your measure should be enclosed in "", and the operator only supports +, -, *, /, <, >, <=, >=, ( and ).',
      };
    }
  }
  if (leftParent !== 0)
    return {
      isValidExpression: false,
      validationErrors: 'Please check if you miss any ) in your expression.',
    };
  return {
    isValidExpression: true,
    validationErrors: '',
  };
}

// Check if value is an operator.
export function isOperator(value: string) {
  const operators = ['+', '-', '*', '/', '(', ')', '<', '>', '<=', '>='];
  return operators.includes(value);
}

// Check if value is an operator, number type and saved Measure.
// or the measure from the current chart.
export function getCalValueType(
  value: any,
  savedMeasures: any,
  allMeasures: any,
) {
  if (value === undefined || value === null || value.trim() === '') {
    return CalValueTypes.Null;
  }
  if (isOperator(value)) {
    return CalValueTypes.Operator;
  }
  if (!Number.isNaN(Number(value))) {
    return CalValueTypes.Number;
  }
  if (getMeaureId(value, savedMeasures)) {
    return CalValueTypes.SavedMeasure;
  }
  if (getMeaureKey(value, allMeasures)) {
    return CalValueTypes.ChartMeasure;
  }
  return CalValueTypes.Null;
}

// Get measure by name.
export function getMeasureByName(measureName: any, allMeasures: any) {
  return allMeasures.find(
    (measure: any) => measure.MeasureName === measureName,
  );
}

// Get measure by measure id.
export function getMeasureById(id: any, savedMeasures: any) {
  return savedMeasures.find(
    (measure: any) => measure.measure_id.toString() === id,
  );
}

// Get measure id by measure name.
// Saved measure will have measure id and and key.
// Measure from the current chart only have key.
export function getMeaureId(measureName: any, allMeasures: any) {
  return getMeasureByName(measureName, allMeasures)?.measure_id?.toString();
}

// Get measure key by measure name.
export function getMeaureKey(measureName: any, savedMeasures: any) {
  return getMeasureByName(measureName, savedMeasures)?.key?.toString();
}

// Get all measure ids for the calculated measure.
export function getMeasureIds(
  calculatedMeasures: any,
  savedMeasures: any,
  allMeasures: any,
) {
  const measureIds = [];
  for (let j = 0; j < calculatedMeasures.length; j += 1) {
    const { value, type } = calculatedMeasures[j];
    const valueType =
      type || getCalValueType(value, savedMeasures, allMeasures);
    if (valueType === CalValueTypes.SavedMeasure) {
      const measureId = calculatedMeasures[j].measure_id;
      if (measureId) {
        measureIds.push(measureId);
      }
    }
  }
  return measureIds;
}
