/* eslint-disable */
import { ColumnMeta } from "@superset-ui/chart-controls";
import { MeasureSeriesOptionEntity, STRING_TYPE_EMPTY_SPEC } from "./signalAlertsTypes";


const is_verbose = false;

export function getOriginalValue(value: number | string, columnType?:string) {
  const isInt8 = columnType === 'INT8';
  if (isInt8) {
    if (value === 'true') {
      return 1;
    } else if (value === 'false') {
      return 0;
    } 
    return -1;
  }

  const isInt64 = columnType === 'NULLABLE(INT32)' ||
    columnType && columnType.startsWith('NULLABLE(DECIMAL') ||
    columnType === 'NULLABLE(INT64)' ||
    columnType === 'NULLABLE(FLOAT)' ||
    columnType === 'NUMERIC';
  if (isInt64) {
    return Number(value);
  }

  if (typeof value === 'number' && Number.isFinite(value)) {
    return value;
  }

  if (value === STRING_TYPE_EMPTY_SPEC) {
    return '';
  } 
  return value;
}

/**
 * The sql generator for signal based chart ingestion pipeline used query 
 * Chart level based
 * @param originalQuerySql 
 * @param measureSeriesList 
 * @param time_grain_sqla 
 * @param granularity_sqla 
 * @param trim_eof_comma 
 * @param columnsFormatInfo 
 * @param granularity_sqla_alias 
 * @param rolling_periods 
 * @returns 
 */
export const generatorSignalSqlV2 = (
  originalQuerySql:string,
  measureSeriesList:MeasureSeriesOptionEntity[],
  time_grain_sqla: string,
  granularity_sqla: string,
  trim_eof_comma: boolean,
  is_enable_timerange_replace: boolean,
  columnsFormatInfo?: ColumnMeta[],
  granularity_sqla_alias?:string,
  rolling_periods?:number,
) => {
  let originalSql = originalQuerySql;

  // 3. find insert position of the generated condition query from the measure-series-list
  const combinedSeriesMultiInCondifionStatement = getCombinedSeriesMultiInConditionStatement(measureSeriesList, columnsFormatInfo)
  if (is_verbose) console.log("# combinedSeriesMultiInCondifionStatement");
  if (is_verbose) console.log(combinedSeriesMultiInCondifionStatement);

  if (combinedSeriesMultiInCondifionStatement === undefined) {
    if (is_enable_timerange_replace) {
      originalSql = replaceMainSelectTimeRangeToLogicTimeRangePlaceholder(originalSql, time_grain_sqla, granularity_sqla, granularity_sqla_alias);
      originalSql = tryReplaceSubSelectTimeRangeToLogicTimeRangePlaceholder(originalSql, time_grain_sqla, granularity_sqla, rolling_periods);
    }
    return trim_eof_comma ? trimEofComma(originalSql) : originalSql;
  }

  // 3.1 find all select statements, 
  const all_select_statements = find_all_select_statements(originalSql);
  if (!all_select_statements) {
    throw new Error('The sql is not valid because no select statement!');
  }

  // 3.1.1 If there has first sub select statement, find the insert position from the first sub select statement(after where keyword)
  const sub_select = getFirstSubSelect(all_select_statements);
  if (sub_select) {
    const sub_select_statement = originalSql.slice(sub_select.statement_span[0], sub_select.statement_span[1]);
    const sub_select_statement_trimed = removeExistDimensionCondition(sub_select_statement);
    originalSql = originalSql.slice(0, sub_select.statement_span[0]) + sub_select_statement_trimed + originalSql.slice(sub_select.statement_span[1]);
    if (is_enable_timerange_replace) {
      originalSql = replaceMainSelectTimeRangeToLogicTimeRangePlaceholder(originalSql, time_grain_sqla, granularity_sqla, granularity_sqla_alias);
      originalSql = tryReplaceSubSelectTimeRangeToLogicTimeRangePlaceholder(originalSql, time_grain_sqla, granularity_sqla, rolling_periods);
    }

    const all_select_statements_2 = find_all_select_statements(originalSql);
    if (!all_select_statements_2) {
      throw new Error('The sql is not valid because no select statement!');
    }
    const sub_select_2 = getFirstSubSelect(all_select_statements_2);
    const sub_select_statement_2 = originalSql.slice(sub_select_2.statement_span[0], sub_select_2.statement_span[1]);
    const sub_select_statement_trimed_2 = removeExistDimensionCondition(sub_select_statement_2);
    const finalSql = leftAppendNewGeneratedDimensionCondition(sub_select_statement_trimed_2, combinedSeriesMultiInCondifionStatement);
    originalSql = originalSql.slice(0, sub_select_2.statement_span[0]) + finalSql + originalSql.slice(sub_select_2.statement_span[1]);
  } else {
    // 3.1.2 Else find the insert position from the main select statement(after where keyword)
    const main_select = getMainSelect(all_select_statements);
    const main_select_statement = originalSql.slice(main_select.statement_span[0], main_select.statement_span[1]);
    const main_select_statement_trimed = removeExistDimensionCondition(main_select_statement);
    originalSql = originalSql.slice(0, main_select.statement_span[0]) + main_select_statement_trimed + originalSql.slice(main_select.statement_span[1]);
    if (is_enable_timerange_replace) {
      originalSql = replaceMainSelectTimeRangeToLogicTimeRangePlaceholder(originalSql, time_grain_sqla, granularity_sqla, granularity_sqla_alias);
      originalSql = tryReplaceSubSelectTimeRangeToLogicTimeRangePlaceholder(originalSql, time_grain_sqla, granularity_sqla, rolling_periods);
    }

    const all_select_statements_2 = find_all_select_statements(originalSql);
    if (!all_select_statements_2) {
      throw new Error('The sql is not valid because no select statement!');
    }
    const main_select_2 = getMainSelect(all_select_statements_2);
    const main_select_statement_2 = originalSql.slice(main_select_2.statement_span[0], main_select_2.statement_span[1]);
    const main_select_statement_trimed_2 = removeExistDimensionCondition(main_select_statement_2);
    const finalSql = leftAppendNewGeneratedDimensionCondition(main_select_statement_trimed_2, combinedSeriesMultiInCondifionStatement);
    originalSql = originalSql.slice(0, main_select_2.statement_span[0]) + finalSql + originalSql.slice(main_select_2.statement_span[1]);
  }
  return trim_eof_comma ? trimEofComma(originalSql) : originalSql;
};

/**
 * Generates a SQL query for signal alerts based on the original query SQL, a list of measure series options,
 * a time granularity SQL string, a granularity SQL string, and an optional rolling period.
 * One measure based
 * 
 * @param originalQuerySql The original query SQL.
 * @param measureSeriesList A list of measure series options.
 * @param time_grain_sqla A time granularity SQL string.
 * @param granularity_sqla A granularity SQL string.
 * @param trim_eof_comma A boolean indicating whether to trim the end of file comma chars (include space chars) 
 * @param granularity_sqla_alias An optional granularity_sqla alias, such as '__timestamp'
 * @param rolling_periods An optional rolling period.
 * @returns The generated SQL query for signal alerts.
 * @throws An error if the SQL is not valid or if the main select statement or the sub select statement cannot be found.
 */
export const generatorSignalSql = (
  originalQuerySql:string,
  measureSeriesList:MeasureSeriesOptionEntity[],
  time_grain_sqla: string,
  granularity_sqla: string,
  trim_eof_comma: boolean,
  columnsFormatInfo?: ColumnMeta[],
  granularity_sqla_alias?:string,
  rolling_periods?:number) => {
  let originalSql = originalQuerySql;
  originalSql = replaceMainSelectTimeRangeToLogicTimeRangePlaceholder(originalSql, time_grain_sqla, granularity_sqla, granularity_sqla_alias);
  originalSql = tryReplaceSubSelectTimeRangeToLogicTimeRangePlaceholder(originalSql, time_grain_sqla, granularity_sqla, rolling_periods);

  // 3. find insert position of the generated condition query from the measure-series-list
  const combinedSeriesCondifionStatement = getCombinedSeriesConditionStatement(measureSeriesList, columnsFormatInfo)
  if (is_verbose) console.log("# combinedSeriesCondifionStatement");
  if (is_verbose) console.log(combinedSeriesCondifionStatement);
  if (combinedSeriesCondifionStatement === undefined) {
    return trim_eof_comma ? trimEofComma(originalSql) : originalSql;
  }

  // 3.1 find all select statements, 
  const all_select_statements_3 = find_all_select_statements(originalSql);
  if (!all_select_statements_3) {
    throw new Error('The sql is not valid because no select statement!');
  }

  // 3.1.1 If there has first sub select statement, find the insert position from the first sub select statement(after where keyword)
  const sub_select_3 = getFirstSubSelect(all_select_statements_3);
  if (sub_select_3) {
    const sub_select_statement_3 = originalSql.slice(sub_select_3.statement_span[0], sub_select_3.statement_span[1]);
    const finalSql = insertConditionStatementToSelectStatement(sub_select_statement_3, combinedSeriesCondifionStatement);
    originalSql = originalSql.slice(0, sub_select_3.statement_span[0]) + finalSql + originalSql.slice(sub_select_3.statement_span[1]);
  } else {
    // 3.1.2 Else find the insert position from the main select statement(after where keyword)
    const main_select_3 = getMainSelect(all_select_statements_3);
    const main_select_statement_3 = originalSql.slice(main_select_3.statement_span[0], main_select_3.statement_span[1]);
    const finalSql = insertConditionStatementToSelectStatement(main_select_statement_3, combinedSeriesCondifionStatement);
    originalSql = originalSql.slice(0, main_select_3.statement_span[0]) + finalSql + originalSql.slice(main_select_3.statement_span[1]);
  }
  return trim_eof_comma ? trimEofComma(originalSql) : originalSql;
};

function trimEofComma(str:string) {
  return str.replaceAll(/[ ;\n]+$/g, '');
}

function findDimensionMultiInConditionInFirstParametreInWhereClause(selectStatement:string, where_condition_start:number) {
  const pattern = /^ *\(\(.+?\) IN \(\(.+?\)\)\) AND/g;
  
  const splittedPrefixSql = selectStatement.slice(where_condition_start);
  const matched = pattern.exec(splittedPrefixSql);
  // matched[0] is the matched string
  // matched.index is the matched string start position
  if (matched === undefined || matched === null) {
    return undefined;
  }

  const matchedString = matched[0];
  const matchedStringStartPosition = matched.index;
  const matchedStringEndPosition = matchedStringStartPosition + matchedString.length;
  return [where_condition_start + matchedStringStartPosition, where_condition_start + matchedStringEndPosition];
}

function removeExistDimensionCondition(selectStatement:string) {
  const wherePosition = findWherePositionFromSelectStatement(selectStatement);
  const toReplaceConditionPosition = findDimensionMultiInConditionInFirstParametreInWhereClause(selectStatement, wherePosition[1]);
  if (toReplaceConditionPosition !== undefined) {
    return `${selectStatement.slice(0, toReplaceConditionPosition[0])}${selectStatement.slice(toReplaceConditionPosition[1])}`;
  }
  return selectStatement;
}

function leftAppendNewGeneratedDimensionCondition(selectStatement:string, newGeneratedDimensionCondition:string) {
  const wherePosition = findWherePositionFromSelectStatement(selectStatement);
  return `${selectStatement.slice(0, wherePosition[1])} ${newGeneratedDimensionCondition} AND${selectStatement.slice(wherePosition[1])}`;
}

function replaceMainSelectTimeRangeToLogicTimeRangePlaceholder(
  querySql:string,
  time_grain_sqla:string,
  granularity_sqla:string,
  granularity_sqla_alias?:string
) {
  // # 1. find all select statements 
  const select_statements = find_all_select_statements(querySql);
  if (!select_statements) {
    throw new Error('The sql is not valid because no select statement!');
  } 
  
  // 1.1 Then find the main select statement
  /* Each object has the following properties:
    * - type: A string indicating whether the SELECT statement is the main one or a subquery.
    * - span: An array of two numbers indicating the start and end positions of the SELECT keyword in the SQL string.
    * - group: A string representing the SELECT statement group (i.e. the part of the SQL string that contains the SELECT statement).
    * - statement: A string representing the full SELECT statement, including any parentheses or other clauses.
    * - statement_span: An array of two numbers indicating the start and end positions of the SELECT statement in the SQL string.
    */
  const main_select = getMainSelect(select_statements);
  if (is_verbose) console.log(main_select);
  if (!main_select) {
    throw new Error('The sql is not valid because no main select statement!');
  }

  // 1.2 find the time column in the main select statement where clause
  // 1.2.1 check is the style of time_column <|<= or >|>=  toDateTime('2023-08-01 00:12:11') | toDateTime('2023-08-01') 
  //       or ----> toYYYYMMDD(time_column) <|<= or >|>= 20230801  , this should be changed to   toYYYYMMDD(event_time) >= toYYYYMMDD(toDateTime('2023-08-01'))
  // 1.2.2 check is find time block miss the end time, if so, add the end time like start time style; 
  //          That means, If start Time is like time_column < toDateTime('2023-08-01 00:00:00'), the end time should be like time_column < toDateTime('{end_time}')
  //          Else, If startt time is like toYYYYMMDD(time_column) < 20230801, the end time should be like toYYYYMMDD(time_column) < toYYYYMMDD(toDateTime({end_time}))
  const main_select_statement = querySql.slice(main_select.statement_span[0], main_select.statement_span[1]);
  // console.log(main_select_statement);
  const replacedTimeRangeWhereClause = replaceTimeRangeFromWhereClause(main_select_statement, time_grain_sqla, granularity_sqla, granularity_sqla_alias, undefined);
  querySql = querySql.slice(0, main_select.statement_span[0]) + replacedTimeRangeWhereClause + querySql.slice(main_select.statement_span[1]);
  return querySql;
}


function tryReplaceSubSelectTimeRangeToLogicTimeRangePlaceholder(
  querySql:string,
  time_grain_sqla:string,
  granularity_sqla:string,
  rolling_periods?:number
) {
  const all_select_statements_2 = find_all_select_statements(querySql);
  if (!all_select_statements_2) {
    throw new Error('The sql is not valid because no select statement!');
  }
  const sub_select = getFirstSubSelect(all_select_statements_2);
  if (is_verbose) console.log(sub_select);
  if (sub_select) {
    // 2.1 find the time column in the sub select statement where clause
    // 2.1.1 check is the style of time_column <|<= or >|>=  toDateTime('2023-08-01 00:12:11') | toDateTime('2023-08-01') 
    //       or ----> toYYYYMMDD(time_column) <|<= or >|>= 20230801  , this should be changed to   toYYYYMMDD(event_time) >= toYYYYMMDD(toDateTime('2023-08-01'))
    const sub_select_statement = querySql.slice(sub_select.statement_span[0], sub_select.statement_span[1]);
    // console.log(sub_select_statement);
    try {
      const replacedTimeRangeWhereClause = replaceTimeRangeFromWhereClause(sub_select_statement, time_grain_sqla, granularity_sqla, undefined, rolling_periods);
      querySql = querySql.slice(0, sub_select.statement_span[0]) + replacedTimeRangeWhereClause + querySql.slice(sub_select.statement_span[1]);
    } catch (e) {
      if (e.message !== 'The sql is not valid because no where clause!') {
        throw e;
      }
    }
  } 
  return querySql;
}



function findWherePositionFromSelectStatement(selectStatement:string) {
  let where_position = rmatch_where(selectStatement, selectStatement.length - 1);
  while (where_position !== null && !where_not_in_alias(selectStatement, 0, where_position[0])) {
    where_position = rmatch_where(selectStatement, where_position[0]);
  }
  if (where_position === null) {
    throw new Error('The sql is not valid because no where clause!');
  }
  return where_position;
}

function insertConditionStatementToSelectStatement(selectStatement:string, combinedSeriesConditionStatement:string) {
  const where_position = findWherePositionFromSelectStatement(selectStatement);
  const newSelectStatement = `${selectStatement.slice(0, where_position[1])} ${combinedSeriesConditionStatement} AND ${selectStatement.slice(where_position[1])}`;
  return newSelectStatement;
}

function getCombinedSeriesMultiInConditionStatement(measureSeriesList:MeasureSeriesOptionEntity[], columnsFormatInfo?:ColumnMeta[]) {
  if (is_verbose) console.log("# measureSeriesList");
  if (is_verbose) console.log(measureSeriesList);

  const seriesList = measureSeriesList
    .filter((measureSeries) => measureSeries.series !== undefined)
    .map((measureSeries) => measureSeries.series);

  if (is_verbose) console.log("# filtered & mapped seriesList");
  if (is_verbose) console.log(seriesList);
  
  // ((dataset_name, app_env) IN (('Android', ''), ('iOS', ''), ('Sync', ''), ('SPOWeb', ''), ('SPOAndroid', '')))
  const dimensionList:string[] = [];
  Object.keys(seriesList).forEach((key) => {
    if (seriesList[key].hasOwnProperty('dimension')) {
      Object.entries(seriesList[key].dimension).forEach(([key, value]) => {
        if (!dimensionList.includes(key)) {
          dimensionList.push(key);
        }
      });
    }
  });
  if (dimensionList.length === 0) {
    return undefined;
  }

  const dimensionValueTupleList:string[][] = [];
  Object.keys(seriesList).forEach((key) => {
    const dimensionValueList:string[] = [];
    if (seriesList[key].hasOwnProperty('dimension')) {
      Object.entries(seriesList[key].dimension).forEach(([key, value]) => {
        if (columnsFormatInfo) {
          // filter fist item x in columnsFormatInfo which x.column_name === key and x.type === 'INT8'
          const filteredInt8 = columnsFormatInfo.filter((x) => x.column_name === key && x.type === 'INT8');
          if (filteredInt8.length > 0) {
            const realValue = function() {
              if (value === 'true') {
                return 1;
              } else if (value === 'false') {
                return 0;
              } 
              return -1;
            }();
            if (is_verbose) console.log(`${realValue}`);
            dimensionValueList.push(`${realValue}`);
          } else {
            const filteredInt64 = columnsFormatInfo.filter(
              (x) => x.column_name === key &&
                (
                  x.type === 'NULLABLE(INT32)' ||
                  x.type && x.type.startsWith('NULLABLE(DECIMAL') ||
                  x.type === 'NULLABLE(INT64)' ||
                  x.type === 'NULLABLE(FLOAT)' ||
                  x.type === 'NUMERIC'
                ));
            if (filteredInt64.length > 0) {
              if (is_verbose) console.log(`${value}`);
              dimensionValueList.push(`${value}`);
            } else {
              // const filterdDatatime = columnsFormatInfo.filter((x) => x.column_name === key && x.type === 'DATETIME');
              // currently no need to deal with the type of array
              // const filteredArray = columnsFormatInfo.filter((x) => x.column_name === key && x.type && x.type.startsWith('ARRAY'));
              if (typeof value === 'number' && Number.isFinite(value)) {
                if (is_verbose) console.log(`${value}`);
                dimensionValueList.push(`${value}`);
              } else {
                // typeof value === 'string' || value instanceof String
                if (value === STRING_TYPE_EMPTY_SPEC) {
                  if (is_verbose) console.log(`''`);
                  dimensionValueList.push(`''`);
                } else {
                  if (is_verbose) console.log(`'${value}'`);
                  dimensionValueList.push(`'${value}'`);
                }
              }
            }
          }
        }
      });
      if (is_verbose) console.log(dimensionValueList);
      dimensionValueTupleList.push(dimensionValueList);
      if (is_verbose) console.log(dimensionValueTupleList);
    }
  });
  if (dimensionValueTupleList.length === 0) {
    return undefined;
  }
  return `((${dimensionList.join(', ')}) IN (${dimensionValueTupleList.map((x) => `(${x.join(', ')})`).join(', ')}))`;
}


function getCombinedSeriesConditionStatement(measureSeriesList:MeasureSeriesOptionEntity[], columnsFormatInfo?:ColumnMeta[]) {
  if (is_verbose) console.log("# measureSeriesList");
  if (is_verbose) console.log(measureSeriesList);

  const operator = ' OR ';
  const seriesList = measureSeriesList
    .filter((measureSeries) => measureSeries.series !== undefined)
    .map((measureSeries) => measureSeries.series);

  if (is_verbose) console.log("# filtered & mapped seriesList");
  if (is_verbose) console.log(seriesList);
  
  const conditionStatementList:string[] = []
  Object.keys(seriesList).forEach((key) => {
    const oneSeriesConditionList:string[] = [];
    if (seriesList[key].hasOwnProperty('dimension')) {
      Object.entries(seriesList[key].dimension).forEach(([key, value]) => {
        if (columnsFormatInfo) {
          // filter fist item x in columnsFormatInfo which x.column_name === key and x.type === 'INT8'
          const filteredInt8 = columnsFormatInfo.filter((x) => x.column_name === key && x.type === 'INT8');
          if (filteredInt8.length > 0) {
            const realValue = function() {
              if (value === 'true') {
                return 1;
              } else if (value === 'false') {
                return 0;
              } 
              return -1;
            }();
            oneSeriesConditionList.push(`${key}=${realValue}`);
          } else {
            const filteredInt64 = columnsFormatInfo.filter(
              (x) => x.column_name === key &&
                (
                  x.type === 'NULLABLE(INT32)' ||
                  x.type && x.type.startsWith('NULLABLE(DECIMAL') ||
                  x.type === 'NULLABLE(INT64)' ||
                  x.type === 'NULLABLE(FLOAT)' ||
                  x.type === 'NUMERIC'
                ));
            if (filteredInt64.length > 0) {
              oneSeriesConditionList.push(`${key}=${value}`);
            } else {
              // const filterdDatatime = columnsFormatInfo.filter((x) => x.column_name === key && x.type === 'DATETIME');
              // currently no need to deal with the type of array
              // const filteredArray = columnsFormatInfo.filter((x) => x.column_name === key && x.type && x.type.startsWith('ARRAY'));
              if (typeof value === 'number' && Number.isFinite(value)) {
                oneSeriesConditionList.push(`${key}=${value}`);
              } else {
                // typeof value === 'string' || value instanceof String
                if (value === STRING_TYPE_EMPTY_SPEC) {
                  oneSeriesConditionList.push(`${key}=''`);
                } else {
                  oneSeriesConditionList.push(`${key}='${value}'`);
                }
              }
            }
          }
        } else {
          if (typeof value === 'number' && Number.isFinite(value)) {
            oneSeriesConditionList.push(`${key}=${value}`);
          } else {
            // typeof value === 'string' || value instanceof String
            oneSeriesConditionList.push(`${key}='${value}'`);
          }
        }
      });
      conditionStatementList.push(`(${oneSeriesConditionList.join(' AND ')})`);
    }
  });

  if (conditionStatementList.length === 0) {
    // this case will happen when all series has no dimension :) 
    //   -> the series we say here is means the pivoted measure-series, not the actually series in sql, the actually series is only have specified dimension conditions
    return undefined;
  }
  return `(${conditionStatementList.join(operator)})`;
}


function getMainSelect(all_select_statements: any[]) {
  const mainSelectList = all_select_statements.filter((select_statement) => select_statement.type === 'main');

  if (mainSelectList.length === 0) {
    return undefined;
  }
  return mainSelectList[0];
};

function getFirstSubSelect(all_select_statements: any[]) {
  const subSelectList = all_select_statements.filter((select_statement) => select_statement.type === 'sub');

  if (subSelectList.length === 0) {
    return undefined;
  }
  return subSelectList[0];
};

function replaceTimeRangeFromWhereClause(
  selectStatement: string,
  time_grain_sqla:string,
  granularity_sqla:string,
  granularity_sqla_alias?:string,
  rolling_period?:number,
) {
  let where_position = rmatch_where(
    selectStatement, selectStatement.length - 1,
  );
  // if (is_verbose) console.log(where_position);
  while (
    where_position !== null &&
    !where_not_in_alias(selectStatement, 0, where_position[0])
  ) {
    where_position = rmatch_where(selectStatement, where_position[0]);
    // if (is_verbose) console.log("where_position = rmatch_where(string, where_position[0])");
    // if (is_verbose) console.log(where_position);
  }
  // if (is_verbose) console.log(where_position);
  if (where_position === null) {
    throw new Error('The sql is not valid because no where clause!');
  }

  const rmatched_groupby = rmatch_groupby(
    selectStatement,
    selectStatement.length - 1,
  );
  const where_condition_start = where_position[1];
  // @ts-ignore
  const where_condition_end = rmatched_groupby
    ? rmatched_groupby[0]
    : selectStatement.length;

  const getTimeRangeMatchedPosition = (selectStatement:string, where_condition_start:number, granularity_sqla:string, operator: '>=' | '<') => {
    // sample:   event_time < toDateTime('2023-03-27 00:00:00')
    const toyyyymmdd_pat1 = `(toYYYYMMDD\\(${granularity_sqla}\\) ${operator} \\d{8})`;
    const toyyyymmdd_pat2 = `(toYYYYMMDD\\(${granularity_sqla}\\) ${operator} toYYYYMMDD\\(toDateTime\\('(\\d{4}-\\d{2}-\\d{2})( \\d{2}:\\d{2}:\\d{2})?'\\)\\))`;
    const toyyyymmdd_pat3 = `(__timestamp ${operator} toYYYYMMDD\\(toDateTime\\('(\\d{4}-\\d{2}-\\d{2})( \\d{2}:\\d{2}:\\d{2})?'\\)\\))`;
    const toDateTime_pat1 = `(${granularity_sqla} ${operator} toDateTime\\('(\\d{4}-\\d{2}-\\d{2})( \\d{2}:\\d{2}:\\d{2})?'\\))`;
    const toDateTime_pat2 = `(__timestamp ${operator} toDateTime\\('(\\d{4}-\\d{2}-\\d{2})( \\d{2}:\\d{2}:\\d{2})?'\\))`;

    const regexString = `${toyyyymmdd_pat1}|${toyyyymmdd_pat2}|${toyyyymmdd_pat3}|${toDateTime_pat1}|${toDateTime_pat2}`;
    const regex = new RegExp(regexString, 'img');

    if (is_verbose) console.log(selectStatement);
    const whereClause = selectStatement.slice(where_condition_start);
    if (is_verbose) console.log(whereClause);
    if (is_verbose) console.log(regexString);

    let matched = regex.exec(whereClause);
    while (matched !== null && matched !== undefined && matched.length > 0) {
      // check is time_range in level1 condition group
      const parenthesisCountPair = get_left_forward_parenthesis_count_pair(
        whereClause,
        matched.index
      );
      if (parenthesisCountPair[0] === parenthesisCountPair[1]) {
        if (is_verbose) console.log(matched.index);

        return matched;
      }
      matched = regex.exec(whereClause);
      if (is_verbose) console.log(matched);
    }
    return matched;
  }

  console.log(`======  ${granularity_sqla} ======`);
  const isGranularitySqlaWrappedByDoubleQuotes = selectStatement.indexOf(`"${granularity_sqla}"`) != -1;
  const real_granularity_sqla = isGranularitySqlaWrappedByDoubleQuotes ? `"${granularity_sqla}"` : granularity_sqla;
  const gteTimeRangePosition = getTimeRangeMatchedPosition(selectStatement, where_condition_start, real_granularity_sqla, '>=');
  const ltTimeRangePosition = getTimeRangeMatchedPosition(selectStatement, where_condition_start, real_granularity_sqla, '<');

  const getOriginalGroup = (matched:RegExpExecArray | null, operator:string) => {
    if (matched !== null && matched !== undefined && matched.length > 0) {
      return matched[0].split(operator)[0].trim();
    }
    return undefined;
  };
  const original_left_group = getOriginalGroup(gteTimeRangePosition, '>=') || getOriginalGroup(ltTimeRangePosition, '<') || '';

  const hasYYYYMMDD = (matched:RegExpExecArray | null, operator:string) => {
    if (matched !== null && matched !== undefined && matched.length > 0) {
      return matched[0].includes('toYYYYMMDD');
    }
    return false;
  };
  const isYYYYMMDD = hasYYYYMMDD(gteTimeRangePosition, '>=') || hasYYYYMMDD(ltTimeRangePosition, '<');

  let signalDataTimeRangePair = [];
  signalDataTimeRangePair = getSignalRollingDataTimeRangePair(
    granularity_sqla_alias ? granularity_sqla_alias : real_granularity_sqla,
    time_grain_sqla,
    rolling_period === undefined ? 0 : rolling_period,
    original_left_group,
    isYYYYMMDD,
  );

  const replace_time_range_block = (
    selectStatement:string,
    where_condition_start:number,
    granularity_sqla:string,
    signalTimeRange:string, 
    operator: '>=' | '<',
  ) => {

    const matched = getTimeRangeMatchedPosition(selectStatement, where_condition_start, granularity_sqla, operator);
    if (matched !== null && matched !== undefined && matched.length > 0) {
      const newSelectStatement =
      selectStatement.slice(0, where_condition_start + matched.index) + signalTimeRange + selectStatement.slice(where_condition_start + matched.index + matched[0].length);
      return newSelectStatement;
    }
    
    if (is_verbose) 
      console.log('in matched empty or matched.index empty');
    // add signalDataTimeRangePair[1] to the start of whereClause start position, and return new whereClause
    const newSelectStatement =
      `${selectStatement.slice(0, where_condition_start)} ${signalTimeRange} AND ${selectStatement.slice(where_condition_start)}`;
    return newSelectStatement;
  };

  let replacedTimerangeSelectStatement = selectStatement;
  if (is_verbose) console.log('#1');
  replacedTimerangeSelectStatement = replace_time_range_block(
    replacedTimerangeSelectStatement,
    where_condition_start,
    real_granularity_sqla,
    signalDataTimeRangePair[0],
    '>='
  );
  if (is_verbose) console.log('#2');
  replacedTimerangeSelectStatement = replace_time_range_block(
    replacedTimerangeSelectStatement,
    where_condition_start,
    real_granularity_sqla,
    signalDataTimeRangePair[1],
    '<',
  );
  return replacedTimerangeSelectStatement;
}

/**
 * @param granularity_sqla time column, simple "event_time"
 * @param time_grain_sqla "P1W" | "P1D" | "PT1H"
 */
function getSignalRollingDataTimeRangePair(
  granularity_sqla: string,
  time_grain_sqla: string,
  rolling_period: number,
  original_left_group: string, 
  isYYYYMMDD?: boolean,
) {
  if (is_verbose) console.log('rolling_period is ', rolling_period);
  if (is_verbose) console.log('offset is ', -1 - rolling_period);

  const endTimePH = isYYYYMMDD ? `toYYYYMMDD(toDateTime('{end_time}'))` : `toDateTime('{end_time}')`;
  const endTimeDayOffsetPH = isYYYYMMDD ? `toYYYYMMDD(date_add({time_unit}, {offset}, toDateTime('{end_time}')))` : `date_add({time_unit}, {offset}, toDateTime('{end_time}'))`;
  let startEndPair = [];
  if (time_grain_sqla === 'PT1H') {
    startEndPair = [
      endTimeDayOffsetPH.replaceAll('{offset}', `${-1 - rolling_period}`).replaceAll('{time_unit}', 'HOUR'),
      endTimePH,
    ];
  } else if(time_grain_sqla === 'P1D') {
    startEndPair = [
      endTimeDayOffsetPH.replaceAll('{offset}', `${-1 - rolling_period}`).replaceAll('{time_unit}', 'DAY'),
      endTimePH,
    ];
  } else if(time_grain_sqla === 'P1W') {
    startEndPair = [
      endTimeDayOffsetPH.replaceAll('{offset}', `${-7 - rolling_period}`).replaceAll('{time_unit}', 'DAY'),
      endTimePH,
    ];
  } else {
    throw new Error(
      `The sql is not valid because the granuality ${time_grain_sqla} is not valid!`,
    );
  }
  const converted_granularity_sqla = isYYYYMMDD ? `toYYYYMMDD(${granularity_sqla})` : `${granularity_sqla}`;
  const final_left_group = original_left_group === '' ? converted_granularity_sqla : original_left_group;
  return [
    `${final_left_group} >= ${startEndPair[0]}`,
    `${final_left_group} < ${startEndPair[1]}`,
  ];
};

const REGEX_NOT_SELECT_KEYWORD_BUT_IN_DOUBLE_QUOTES =
  /select[^\n"]*"[ \n]*(,|from)/i;
const REGEX_NOT_SUB_SELECT_KEYWORD_BUT_IN_DOUBLE_QUOTES = 
  /\([ \n]*select[^\n"]*"[ \n]*(,|from)/i;

/**
 * Finds all the SELECT statements in the given SQL string.
 *
 * @param sql The SQL string to search for SELECT statements.
 * @returns An array of objects representing each SELECT statement found, or null if no SELECT statements were found or the SQL string is invalid.
 * Each object has the following properties:
 * - type: A string indicating whether the SELECT statement is the main one or a subquery.
 * - span: An array of two numbers indicating the start and end positions of the SELECT keyword in the SQL string.
 * - group: A string representing the SELECT statement group (i.e. the part of the SQL string that contains the SELECT statement).
 * - statement: A string representing the full SELECT statement, including any parentheses or other clauses.
 * - statement_span: An array of two numbers indicating the start and end positions of the SELECT statement in the SQL string.
 */
function find_all_select_statements(sql: string): any[] | null {
  const select_statements = [];
  let search_start = 0;
  let pure_select_match = match_pure_select(sql, search_start);
  if (pure_select_match === null) {
    return null;
  }

  let statement_context = {};
  while (statement_context !== null) {
    if (is_verbose) console.log(`search_start, ${search_start}`);

    pure_select_match = match_pure_select(sql, search_start);
    if (pure_select_match === null) {
      break;
    }

    let select_keyword_match = match_select(sql, search_start);
    const is_main_select_style = 
      select_keyword_match === null
      ? false
      : reverse_match_main_select_style(sql, select_keyword_match[0] - 1);
    let parenthesis_wrappered_select_keyword_match =
      match_parenthsis_wrappered_sub_select(sql, search_start);
    if (is_verbose) console.log(`pure_select_match, ${pure_select_match}`);
    if (is_verbose)
      console.log(`is_main_select_style, ${is_main_select_style}`);
    if (is_verbose)
      console.log(`select_keyword_match, ${select_keyword_match}`);
    if (is_verbose)
      console.log(
        `parenthesis_wrappered_select_keyword_match is,
        ${parenthesis_wrappered_select_keyword_match}`
      );

    if (select_keyword_match === null) {
      search_start = pure_select_match[1];
      statement_context = {};
      parenthesis_wrappered_select_keyword_match = null;
      pure_select_match = null;
      continue;
    }

    // eslint-disable-next-line prettier/prettier
    if (is_main_select_style && (
        (parenthesis_wrappered_select_keyword_match === null) ||
        (parenthesis_wrappered_select_keyword_match !== null && select_keyword_match[1] !== parenthesis_wrappered_select_keyword_match[1])
    )) {
      statement_context = {};
      // eslint-disable-next-line prefer-destructuring, dot-notation
      statement_context['type'] = 'main';
      // eslint-disable-next-line prefer-destructuring, dot-notation
      statement_context['span'] = select_keyword_match;
      // eslint-disable-next-line prefer-destructuring, dot-notation
      statement_context['group'] = sql.slice(
        // eslint-disable-next-line prefer-destructuring, dot-notation
        statement_context['span'][0],
        // eslint-disable-next-line prefer-destructuring, dot-notation
        statement_context['span'][1],
      );
      // eslint-disable-next-line prefer-destructuring, dot-notation
      statement_context['statement'] = sql.slice(statement_context['span'][0]);
      // eslint-disable-next-line prefer-destructuring, dot-notation
      statement_context['statement_span'] = [
        // eslint-disable-next-line prefer-destructuring, dot-notation
        statement_context['span'][0],
        sql.length,
      ];
      select_statements.push(statement_context);
      // eslint-disable-next-line prefer-destructuring, dot-notation
      search_start = statement_context['span'][1];
    } else {
      if (parenthesis_wrappered_select_keyword_match === null) {
        search_start = select_keyword_match[1];
        statement_context = {};
        parenthesis_wrappered_select_keyword_match = null;
        pure_select_match = null;
        continue;
      }

      if (is_verbose)
        console.log('pure_select_match[1], ', select_keyword_match[1]);
      if (is_verbose)
        console.log(
          'parenthesis_wrappered_select_match[1], ',
          parenthesis_wrappered_select_keyword_match[1],
        );
      statement_context = {};
      // eslint-disable-next-line prefer-destructuring, dot-notation
      statement_context['type'] = 'sub';
      // eslint-disable-next-line prefer-destructuring, dot-notation
      statement_context['span'] = parenthesis_wrappered_select_keyword_match;
      // eslint-disable-next-line prefer-destructuring, dot-notation
      statement_context['group'] = sql.slice(
        // eslint-disable-next-line prefer-destructuring, dot-notation
        statement_context['span'][0],
        search_start,
      );
      // eslint-disable-next-line prefer-destructuring, dot-notation
      const parenthesis_span = match_couple_parenthesis(
        sql,
        statement_context['span'][0]
      );
      if (parenthesis_span === null || parenthesis_span === undefined) {
        // if parenthesis_span is null or undefined means the sql is not valid, return directly
        return null;
      }
      // eslint-disable-next-line prefer-destructuring, dot-notation
      statement_context['statement'] = sql.slice(
        parenthesis_span[0],
        parenthesis_span[1],
      );
      // eslint-disable-next-line prefer-destructuring, dot-notation
      statement_context['statement_span'] = [
        parenthesis_span[0],
        parenthesis_span[1],
      ];
      select_statements.push(statement_context);
      // eslint-disable-next-line prefer-destructuring, dot-notation
      search_start = statement_context['span'][1];
    }

    select_keyword_match = null;
    parenthesis_wrappered_select_keyword_match = null;
    pure_select_match = null;
  }

  return select_statements;
}

function where_not_in_alias(str: string, start: number, end: number): boolean {
  const regex =
    // eslint-disable-next-line no-useless-escape
    /(((as[ \n\r]+)|(order by[ \n\r]+))"[a-z0-9 \-_\'\(\)]+$)|(((as[ \n\r]+)|(order by[ \n\r]+))\'[a-z0-9 \-_"\(\)]+$)|(((as[ \n\r]+)|(order by[ \n\r]+))[a-z0-9\-_]+$)/i;
  const matches = str.slice(start, end).match(regex);
  return matches === null;
}

// @ts-ignore
function match_where_expr_eof_keyword_position(expr: string): number[] | null {
  const eof_keyword_list = [
    'group by',
    'having',
    'order by',
    'limit',
    'union',
    'into outfile',
    ';',
    '$',
  ];
  const eof_keyword_regex = `(${eof_keyword_list.join('|')})`;
  const match = expr
    .toLowerCase()
    .match(new RegExp(`(?<=[\n ])(${eof_keyword_regex}(?=[\n $]))|$`));
  if (match === null || match.index === undefined) {
    return null;
  }
  return [match.index, match.index + match[0].length];
}

function match_parenthsis_wrappered_sub_select(
  string: string,
  start: number,
): number[] | null {
  const regex = /\([ \n]*select(?=[ \n])/i;
  const matcher = string.slice(start).match(regex);
  if (matcher !== null && matcher.index !== undefined) {
    const notSelectKeywordMatcher = string
      .slice(start)
      .match(REGEX_NOT_SUB_SELECT_KEYWORD_BUT_IN_DOUBLE_QUOTES);
    if (
      notSelectKeywordMatcher !== null &&
      notSelectKeywordMatcher.index === matcher.index
    ) {
      return null;
    }
    return [matcher.index + start, matcher.index + start + matcher[0].length];
  }
  return null;
}

function match_select(string: string, start: number): Array<number> | null {
  const matcher = regex_match_keywords_case_insensitive(
    string,
    start,
    'select(?=[ \n])',
  );
  if (matcher !== null) {
    const not_select_keyword_matcher =
      REGEX_NOT_SELECT_KEYWORD_BUT_IN_DOUBLE_QUOTES.exec(string);
    if (is_verbose) console.log('### match_select matcher ###');
    if (is_verbose) console.log(matcher);
    if (is_verbose)
      console.log('### match_select not_select_keyword_matcher ###');
    if (is_verbose) console.log(not_select_keyword_matcher);
    if (
      not_select_keyword_matcher !== null &&
      not_select_keyword_matcher.index === matcher[0]
    ) {
      return null;
    }
    return matcher;
  }
  return null;
}

function reverse_match_main_select_style(
  string: string,
  start: number,
): boolean {
  if (start >= string.length) {
    return false;
  }
  for (let i = start; i >= 0; i -= 1) {
    if (is_verbose) console.log(`index, ${i}, ${string[i]}`);
    if (string[i] !== ' ' && string[i] !== '\n') {
      return false;
    }
    if (string[i] === '\n') {
      return true;
    }
  }
  return true;
}

function match_pure_select(string: string, start: number): number[] | null {
  return regex_match_keywords_case_insensitive(string, start, 'select');
}

function match_couple_parenthesis(
  string: string,
  start: number,
): number[] | null {
  if (start >= string.length) {
    return null;
  }
  if (string[start] !== '(') {
    return null;
  }
  let left_parenthesis_count = 1;
  let right_parenthesis_count = 0;
  for (let i = start + 1; i < string.length; i += 1) {
    if (string[i] === '(') {
      left_parenthesis_count += 1;
    } else if (string[i] === ')') {
      right_parenthesis_count += 1;
    }
    if (left_parenthesis_count === right_parenthesis_count) {
      return [start, i + 1];
    }
  }
  return null;
}

function rmatch_keywords_case_insensitive(
  string: string,
  start: number,
  keyword: string,
): number[] | null {
  const eof_keyword = keyword;
  if (start >= string.length) {
    if (is_verbose)
      console.log(
        `start, string.length: ${start}, ${string.length}, return null`,
      );
    return null;
  }
  for (let i = start; i >= 0; i -= 1) {
    if (string[i].toLowerCase() === eof_keyword[eof_keyword.length - 1]) {
      let j = i;
      while (j >= 0 && eof_keyword.length - (i - j) - 1 >= 0) {
        const eof_keyword_index = eof_keyword.length - (i - j) - 1;
        if (string[j].toLowerCase() === eof_keyword[eof_keyword_index]) {
          j -= 1;
        } else {
          break;
        }
      }
      if (eof_keyword.length === i - j) {
        return [j + 1, i + 1];
      }
    }
  }
  return null;
}

function rmatch_where(str: string, start: number): number[] | null {
  const eofKeyword = 'where';
  return rmatch_keywords_case_insensitive(str, start, eofKeyword);
}

function rmatch_groupby(str: string, start: number): number[] | null {
  const eof_keyword = 'group by';
  return rmatch_keywords_case_insensitive(str, start, eof_keyword);
}
// @ts-ignore
function match_keywords_case_insensitive(
  string: string,
  start: number,
  keyword: string,
): number[] | null {
  const eof_keyword = keyword;
  if (start >= string.length) {
    return null;
  }
  for (let i = start; i < string.length; i += 1) {
    if (string[i].toLowerCase() === eof_keyword[0]) {
      let j = i;
      while (j < string.length && j - i < eof_keyword.length) {
        if (string[j].toLowerCase() === eof_keyword[j - i]) {
          j += 1;
        } else {
          break;
        }
      }
      if (j - i === eof_keyword.length) {
        return [i, j];
      }
    }
  }
  return null;
}

function get_left_forward_parenthesis_count_pair(
  string: string,
  start: number,
): number[] {
  let lpc = 0;
  let rpc = 0;
  for (let i = start; i >= 0; i -= 1) {
    if (string[i] === '(') {
      lpc += 1;
    } else if (string[i] === ')') {
      rpc += 1;
    }
  }
  return [lpc, rpc];
}

// @ts-ignore
function get_right_forward_parenthesis_count_pair(
  string: string,
  start: number,
): number[] {
  let lpc = 0;
  let rpc = 0;
  for (let i = start; i < string.length; i += 1) {
    if (string[i] === '(') {
      lpc += 1;
    } else if (string[i] === ')') {
      rpc += 1;
    }
  }
  return [lpc, rpc];
}

// @ts-ignore
function find_right_char(string: string, start: number, char: string): number {
  if (start >= string.length) {
    return -1;
  }
  for (let i = start; i < string.length; i += 1) {
    if (string[i] === char) {
      return i;
    }
  }
  return -1;
}

function regex_match_keywords_case_insensitive(
  string: string,
  start: number,
  pattern: string,
): number[] | null {
  if (start >= string.length) {
    return null;
  }
  const to_search_str = string.slice(start);
  const match = to_search_str.match(new RegExp(pattern, 'i'));
  if (match !== null && match.index !== undefined) {
    return [match.index + start, match.index + match[0].length + start];
  }
  return null;
}
