import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import { NezhaTelemetry } from 'src/telemetry/helper/CommonTelemetryHelper';
import { NODE_TYPE_ENUM } from '../constants/CustomizedEventsContants';


export const startAutoCaptureHtmlLinkAClick = (analytics:ApplicationInsights) => {
  
  document.addEventListener('click', function (event) {
    const target = event.target as HTMLElement;
  
    // Check if the clicked element is an <a> tag
    if (target.tagName === 'A') {
      console.info('Clicked:', target);
      // You can add more tracking logic here, e.g., sending data to an analytics service
      NezhaTelemetry.trackClickEvent(analytics, target.innerText, NODE_TYPE_ENUM.HTML_A);
  
      // Optionally, you can prevent the default action if needed
      // event.preventDefault();
    }
  });
};
