import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApplicationInsights, IEventProperty, IExtendedConfiguration, } from '@microsoft/1ds-analytics-web-js';
// @ts-ignore
import { ValueKind, EventPropertyType, addEventHandler } from '@microsoft/1ds-core-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthPlugin, AuthType, IAuthenticationConfiguration, } from '@microsoft/1ds-auth-js';
import getBootstrapData from 'src/utils/getBootstrapData';
import { getDomain, getEnvironment } from './util';
import { NEZHA_COMMON_FIELDS_NAME } from './constants/CommonEventsContants';
import { startAutoCapturePageView } from './capture/AutoCapturePageViewEvents';
import { INSTRUMENTATION_KEY } from './config';
import { startAutoCaptureHtmlLinkAClick } from './capture/AutoCaptureHtmlLinkAClickEvents';


export const analytics: ApplicationInsights = new ApplicationInsights();

const authPlugin: AuthPlugin = new AuthPlugin();
const authConfig: IAuthenticationConfiguration = {
  authType: AuthType.AAD,
  loggedInStatusCallback: () => true,
};
export function setupAnalytics() {
  const bootstrapData = getBootstrapData();
  // eslint-disable-next-line prefer-destructuring
  const user = bootstrapData.user;
  const userId = user?.userId;

  const config: IExtendedConfiguration = {
    // test ring
    instrumentationKey: INSTRUMENTATION_KEY,
    // extensions: [authPlugin],
    channelConfiguration: {
      // Post channel configuration
      eventsLimitInMem: 5000,
    },
    propertyConfiguration: {
      // Properties Plugin configuration
      userAgent: navigator.userAgent,
      enableApplicationInsightsUser: true,
      userConsented: true,
    },
    webAnalyticsConfiguration: {
      // Web Analytics Plugin configuration
      autoCapture: {
        scroll: false,
        pageView: true,
        onLoad: true,
        onUnload: true,
        click: true,
        resize: true,
        jsError: true,
      },
      coreData: {
        pageTags: {
          metaTags: {},
          userTags: {}
        }
      },
    },
  };
  config.extensionConfig = {};
  config.extensionConfig[authPlugin.identifier] = authConfig;
  // Initialize SDK
  analytics.initialize(config, []);

  // add user id to common fields, and mark it is a sensitive info should be auto-hashed in Aria
  const userPuidProp: IEventProperty = {
    value: userId?.toString() || '',
    kind: ValueKind.Pii_Identity,
    propertyType: EventPropertyType.String,
  };
  analytics.getPropertyManager().setProperty(NEZHA_COMMON_FIELDS_NAME.UserPuid, userPuidProp);
  
  // add domain to common fields
  const domainProp: string = getDomain();
  analytics.getPropertyManager().setProperty(NEZHA_COMMON_FIELDS_NAME.Endpoint, domainProp);

  // add environment to common fields
  const envProp: string = getEnvironment();
  analytics.getPropertyManager().setProperty(NEZHA_COMMON_FIELDS_NAME.Environment, envProp);

  // Now enable the default auto capture events provider by 1DS SDK self
  // --MS.Web.PageView
  // --MS.Web.PageAction
  // --MS.Web.ContentUpdate
  // --MS.Web.PageUnload
  // --MS.Web.ClientError
  // --MS.Web.Performance

  startAutoCapturePageView(analytics);
  startAutoCaptureHtmlLinkAClick(analytics);
}

export function getUserFromBootstrapDataForAnalytics() {
  const bootstrapData = getBootstrapData();
  const user = { ...bootstrapData.user };
  return user;
}

// Create AnalyticsContext
export const AnalyticsContext = React.createContext(analytics);
