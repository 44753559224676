import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import { NEZHA_OPERATION } from 'src/telemetry/constants/CommonEventsContants';
import { NezhaTelemetry } from 'src/telemetry/helper/CommonTelemetryHelper';
import { getPathname, parseScenarioFromUri } from 'src/telemetry/util';

export const trackChangeEvent = (
  analytics: ApplicationInsights,
  nestedText: string,
  nodeType: string,
) => {
  try {
    if (analytics) {
      console.info('Track Change Event:', nestedText);
      NezhaTelemetry.trackEngagementEvent(analytics, {
        scenario: parseScenarioFromUri(getPathname()),
        operation: NEZHA_OPERATION.CHANGE,
        customProperties: {
          content: nestedText,
          nodeType,
        },
      });
    }
  } catch (error) {
    console.warn('Error tracking Change event:', error);
  }
};
